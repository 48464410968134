.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-12 {
        height: rem(12);
        width: rem(12);
    }

    &-15 {
        height: rem(15);
        width: rem(15);
    }

    &-18 {
        height: rem(18);
        width: rem(18);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-23 {
        height: rem(23);
        width: rem(23);
    }

    &-28 {
        height: rem(28);
        width: rem(28);
    }

    &-30 {
        height: rem(30);
        width: rem(30);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-36 {
        height: rem(36);
        width: rem(36);
    }

    &-38 {
        height: rem(38);
        width: rem(38);
    }

    &-80 {
        height: rem(80);
        width: rem(80);
    }

    &-check {
        background-image: url('~assets/icons/ic_check_condition.svg');
    }

    &-arrowDown {
        background-image: url('~assets/icons/ic_arrow_down.svg');
    }

    &-clear {
        background-image: url('~assets/icons/ic_clear.svg');
    }
}
