.a-input {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: rem(-4);
        left: rem(-4);
        width: 100%;
        height: rem(74);
        border-radius: rem(40);
        background: linear-gradient(90deg, #ffef70 0%, #b66b03 15%, #e9c64f 28%, #ffef70 33%, #b66b03 50%, #e9c64f 62%, #ffef70 66%, #b66b03 82%, #e9c64f 95%, #ffef70 100%);
    }

    input {
        width: calc(100% - rem(8));
        height: rem(66);
        border-radius: rem(40);
        z-index: 1;
        border-color: transparent;
        padding: rem(24);
        @include font-base(26, 36);
        color: $black;

        &:read-only {
            background-color: #ccc;
        }
    }
}
