.p-confirm {
    margin-right: rem(60);
    margin-left: rem(60);

    &_signatureWrapper {
        height: rem(380);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border-radius: rem(40);
            background: linear-gradient(90deg, #ffef70 0%, #b66b03 15%, #e9c64f 28%, #ffef70 33%, #b66b03 50%, #e9c64f 62%, #ffef70 66%, #b66b03 82%, #e9c64f 95%, #ffef70 100%);
        }

        canvas {
            background-color: $white;
            border-radius: rem(40);
            position: relative;
            width: 100%;
            height: rem(380);
        }
    }

    &_form {
        margin-top: rem(80);
    }

    &_title {
        display: flex;
        margin-bottom: rem(16);

        p {
            text-align: left;

            &:first-child {
                margin-right: rem(8);
                flex-shrink: 0;
            }

            &:last-child {
                white-space: nowrap;
                font-size: rem(24);
                line-height: rem(36);
                font-weight: 700;
                color: $white;
            }
        }
    }

    &_confirmButton {
        position: relative;
        width: 100%;
        max-width: rem(446);
        margin: rem(40) auto 0;
        cursor: pointer;

        &-loading {
            cursor: not-allowed;

            .p-confirm_confirmButton {
                &_img {
                    opacity: 0.5;
                }

                &_indicator {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &_conditionWrap {
        display: flex;
        margin-top: rem(16);
    }

    &_conditionWrapper {
        margin-top: rem(60);
        margin-bottom: rem(32);
    }

    &_condition {
        width: rem(38);
        height: rem(38);
        background-color: $white;
        border-radius: 50%;
        flex-shrink: 0;
        transition: all 0.25s ease-in-out;
        margin-right: rem(24);
        margin-top: rem(4);
    }

    &_termsText {
        font-style: italic;
        margin-top: rem(8);
        
        &-error {
            color: $ku-crimson;
        }

        &-checkin {
            margin-bottom: rem(8) !important;
        }
    }

    &_clear {
        cursor: pointer;
        position: absolute;
        top: 3%;
        right: 2%;
    }

    &_conventionTitle {
        text-align: center;
        color: $white;
    }

    &_userInfo {
        margin-top: rem(30);
        color: $white;
    }
}
