// fonts
@font-face {
    font-display: swap;
    font-family: 'Brandon Text F';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}BrandonTextF-Bold.woff2') format('woff2'),
        url('#{$font-path}BrandonTextF-Bold.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'Brandon Text F';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}BrandonTextF-Medium.woff2') format('woff2'),
        url('#{$font-path}BrandonTextF-Medium.woff') format('woff');
}
  
@font-face {
    font-display: swap;
    font-family: 'Brandon Text F';
    font-style: normal;
    font-weight: normal;
    src: url('#{$font-path}BrandonTextF-Regular.woff2') format('woff2'),
        url('#{$font-path}BrandonTextF-Regular.woff') format('woff');
}
