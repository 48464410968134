.m-datePicker {
    $root: &;
    position: relative;

    &_wrapper {
        @include font-base(18, 28);
        border-radius: rem(5);
        padding: rem(12) rem(16);
        position: relative;
        width: 100%;

        &:focus {
            outline: 0;
        }
    }
  
    &-sm {
        #{$root}_container {
            #{$root}_wrapper {
                padding: rem(4) rem(12);
            }

            input {
                background-position: right rem(12) top rem(6);
                background-size: rem(14);
            }
        }
    }

    &_container {
        position: relative;
        width: 100%;

        input {
            background-image: url('~assets/icons/ic_calendar.svg');
            background-position: right rem(16) top rem(20);
            background-repeat: no-repeat;
            background-size: rem(20);
            border: 1px solid $white;
        }
    }

    &_header {
        @include adjust-flex(center, center);
        flex-direction: row-reverse;

        .m-pulldown {
            &:first-child {
                min-width: rem(88);
                margin-left: rem(8);
            }
        }
    }

    &_calendar {
        border: 0 !important;
        padding: rem(24) rem(16);
        box-shadow: 0 3px 10px rgba($black, 0.6);
        @include tablet-down {
            padding: rem(16);
        }
        position: absolute !important;
        right: rem(8);
        top: rem(64);

        .react-datepicker__day-names {
            display: flex;
            margin-bottom: 0;
        }

        .react-datepicker__day--outside-month {
            opacity: 0.4;
        }

        .react-datepicker__month {
            margin: 0;
        }

        .react-datepicker__day-name {
            @include font-base(14, 21);
            align-items: center;
            display: flex;
            flex-direction: column;
            font-weight: 700;
            height: rem(40);
            justify-content: center;
            width: rem(40);

            @include tablet-down {
                height: rem(32);
                width: rem(32);
            }
            @include mobile-down {
                height: rem(24);
                width: rem(24);
            }
        }

        .react-datepicker__week {
            display: flex;
        }

        .react-datepicker__day {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            height: rem(40);
            justify-content: center;
            width: rem(40);
            @include font-base(14, 21);
            @include tablet-down {
                height: rem(32);
                width: rem(32);
            }
            @include mobile-down {
                height: rem(24);
                width: rem(24);
            }
        }

        .react-datepicker__day--today {
            border-radius: 50%;
            font-weight: 500;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day:hover,
        .react-datepicker__day--selected {
            background: $black;
            color: $white;
        }
    }

    &_popper {
        .react-datepicker__header {
            background: $white;
            border-bottom: 0;
            padding: 0;

            .m-pulldown {
                &::before {
                    background: none;
                }

                &__control {
                    width: 100%;
                }

                &__single-value {
                    @include font-base(20, 26);
                    font-weight: 400;
                }
            }
        }
    }

    &_label {
        color: $ku-crimson;
        margin-bottom: rem(4);
        text-transform: uppercase;
        @include font-base(18, 28);
    
        @include adjust-flex(flex-start, center);

        @include mobile-down {
            @include font-base(12, 20);
        }

        &-required {
            color: $ku-crimson;
            margin-left: rem(4);
        }

        &-icon {
            margin-right: rem(4);
        }
    }

    &_iconError {
        margin-left: rem(4);
    }

    &-light {
        #{$root}_wrapper {
            background-color: $white;
        }
    }

    &-borderRadiusColor {
        #{$root}_wrapper {
            border-color: $ku-crimson;
            border-radius: rem(12);
        }
    }

    .react-datepicker-popper {
        z-index: 3;
        right: 0 !important;
        transform: translateX(0) !important;
    }
}
