.p-register {
    margin-top: rem(60);
    
    &_confirmText {
        color: $anti-flash-white;
        text-align: center;
        text-shadow: 0.2rem 0.15rem 0.675rem rgba(0, 0, 0, 0.6);
    }

    &_form {
        margin-top: rem(100);
    }

    &_field {
        @include adjust-flex(space-between, center);

        &_fieldText {
            width: rem(250);
            margin-right: rem(24) !important;
            flex-shrink: 0;
        }

        &_input {
            flex-grow: 1;
        }

        &:not(:first-child) {
            margin-top: rem(36);
        }
    }

    &_error {
        &-text {
            color: $ku-crimson;
            margin-top: rem(12) !important;
        }
    }

    &_buttonGroup {
        margin-top: rem(160);
    }

    &_checkButton,
    &_signupButton {
        width: 100%;
        max-width: rem(446);
        margin: 0 auto;
        cursor: pointer;
    }

    &_checkButton {
        margin-top: rem(12);
    }

    &_signupButton {
        margin-top: rem(28);
    }
}
