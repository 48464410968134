.p-userInfo {
    margin-top: rem(24);

    &_confirmText {
        color: $anti-flash-white;
        text-align: center;
        text-shadow: rem(8) rem(4) rem(6) $black;
    }

    &_form {
        margin-top: rem(24);
    }

    &_field {
        @include adjust-flex(space-between, center);
        margin-right: rem(4);

        &:not(:first-child) {
            margin-top: rem(24);
        }
    }

    &_confirmButton {
        width: 100%;
        max-width: rem(446);
        margin: rem(32) auto 0;
        cursor: pointer;
    }

    &_modal {
        color: $anti-flash-white;
        overflow-y: auto;

        &_content {
            margin-top: rem(24);
            text-align: justify;
            padding: rem(12);
        }

        &_completeButton {
            width: 100%;
            max-width: rem(446);
            margin: rem(16) auto 0;
            cursor: pointer;
        }
    }

    &_conditionWrap {
        @include adjust-flex;
        margin-top: rem(60);
    }

    &_condition {
        width: rem(38);
        height: rem(38);
        background-color: $white;
        border-radius: 50%;
        flex-shrink: 0;
        transition: all 0.25s ease-in-out;
        margin-right: rem(24);
    }

    &_termsText {
        &-error {
            color: $ku-crimson;
            margin-top: rem(12) !important;
        }
    }
}

.ReactModal__Overlay {
    z-index: 2;
    background-image: url('~assets/images/main-background-modal.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Content {
    padding: rem(28) !important;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(-100px);
}
