.p-congratulation {
    margin-top: rem(100);

    &_congraText {
        background: linear-gradient(90deg, #ba730e 0, #fde583 48%, #c1842d 97.75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 6px 5px rgba(0, 0, 0, 0.4));
    }

    &_completeButton {
        width: 100%;
        max-width: rem(446);
        margin: rem(120) auto 0;
        cursor: pointer;
    }
}
