.t-mainLayout {
    position: relative;
    background-image: url('~assets/images/main-background-modal.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding: rem(60) rem(40) rem(120);
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &_top {
        flex-shrink: 0;
    }

    &_heading {
        background: linear-gradient(90deg, #ba730e 0, #fde583 48%, #c1842d 97.75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 6px 5px rgba(0, 0, 0, 0.4));
    }

    &_subHeading {
        margin-top: rem(42) !important;
        color: $anti-flash-white;
        text-shadow: 0.2rem 0.15rem 0.675rem rgba(0, 0, 0, 0.6);
    }

    &_content {
        position: relative;
    }
}
