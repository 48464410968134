.m-pulldown {
    $root: &;

    &-error {
        #{$root}__control {
            border-color: $red;
        }
    }
    /* Customize */
    &__input {
        font-size: rem(14) !important;
        font-family: inherit !important;
        caret-color: transparent;

        &-container {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: rem(-4);
        left: rem(-4);
        width: 100%;
        height: rem(74);
        border-radius: rem(40);
        background: linear-gradient(90deg, #ffef70 0%, #b66b03 15%, #e9c64f 28%, #ffef70 33%, #b66b03 50%, #e9c64f 62%, #ffef70 66%, #b66b03 82%, #e9c64f 95%, #ffef70 100%);
    }

    &__single-value {
        height: rem(28);
        display: flex;
        align-items: center;
    }

    &__indicators {
        padding-left: 0 !important;
        padding-right: rem(12) !important;
    }

    &__indicator-separator {
        display: none;
    }

    &__value-container {
        font-size: rem(14);
        padding-left: rem(16) !important;
        padding-right: 0 !important;
        height: rem(32);
    }

    &__menu {
        &-list {
            max-height: rem(200) !important;
            @include scroll-bars(4, $oxford-blue, transparent, 4, 1);
        }
    }

    &__control {
        height: rem(66);
        width: calc(100% - rem(8));
        border-radius: rem(40) !important;
        border-color: transparent !important; 
    }

    &__single-value,
    &__placeholder {
        @include font-base(26, 36);
        font-weight: 500;
    }

    &-datepicker {
        #{$root}__control {
            height: rem(38) !important;
        }
        #{$root}_icon-indicator {
            height: rem(20) !important;
            width: rem(20) !important;
            background-position-x: 0;
        }
    }
}
